<template>
  <div class="home-passed-dashboard">
    <v-data-table
      :value="selected"
      :headers="reportHeaders"
      :items="reportData"
      :show-select="true"
      checkbox-color="primary"
      item-key="processInstanceId"
      class="elevation-1"
      :loading="dataLoading"
      loading-text="Daten werden geladen..."
      no-data-text="Es sind keine Einträge vorhanden."
      no-results-text="Für den eingegebenen Suchbegriff konnten keine Einträge gefunden werden."
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        'items-per-page-text': 'Zeilen pro Seite:',
        'items-per-page-options': [10, 20, 60, 120, 500]
      }"
      :items-per-page="60"
      :custom-filter="customFilter"
      :search="searchText"
      group-by="project"
      sort-by=""
      :server-items-length="totalDataTableCount"
      :options.sync="options"
      @item-selected="itemSelected($event)"
      @click:row="rowClicked"
      @toggle-select-all="selectAllTriggered($event)"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>
            Übersicht laufender Homepassedprozesse
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-toolbar flat>
          <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            label="Suchen nach Projektname, Projektnummer, Kundennummer oder Vertragsnummer"
            single-line
            hide-details
            clearable
            class="space-left"
          />
        </v-toolbar>
      </template>
      <template v-slot:group.header="{ items, isOpen, toggle }">
        <td :colspan="reportHeaders.length">
          <v-icon @click="toggle">
            {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
          <span>Projekt: {{ items[0].project }}, Projektphase:
            {{ items[0].projectDescription }}
          </span>
        </td>
      </template>
    </v-data-table>
    <v-progress-linear
      v-if="oldItemsPerPage != 10"
      indeterminate
      :active="dataLoading"
    ></v-progress-linear>
    <div class="button-wrapper">
      <div class="text">
        <v-dialog v-model="dialogAccept" max-width="1000px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              :disabled="selected.length === 0"
              color="primary"
              v-on="on"
            >
              <v-icon small>
                mdi-check-circle
              </v-icon>
              Akzeptieren
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span
                class="text-h5"
              >Projektstatus für diese Adressen akzeptieren?</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-data-table
                  :headers="reportHeaders"
                  :items="selected"
                  item-key="processInstanceId"
                  class="elevation-1"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :footer-props="{
                    showFirstLastPage: true,
                    showCurrentPage: true,
                    'items-per-page-text': 'Zeilen pro Seite:',
                    'items-per-page-options': [10, 25, 50, 100]
                  }"
                >
                </v-data-table>
                <div class="button-wrapper">
                  <v-btn
                    :disabled="selected.length === 0 || disableButtons"
                    color="green"
                    text
                    @click="execute(selected, 'accept')"
                  >
                    <v-icon small>
                      mdi-check-circle
                    </v-icon>
                    Akzeptieren
                  </v-btn>
                  <v-btn
                    color="red"
                    text
                    @click="closeAcceptDialog"
                  >
                    <v-icon dark>
                      mdi-cancel
                    </v-icon>
                    Abbrechen
                  </v-btn>
                  <v-alert
                    v-if="selectedWarning"
                    type="warning"
                    dense
                    text
                  >
                    Achtung! Es wurden {{ selected.length }} Verträge ausgewählt.
                  </v-alert>
                </div>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
      <div class="text">
        <v-dialog
          v-model="dialogSaveForSecondConstructionPhase"
          max-width="1000px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              :disabled="selected.length === 0"
              color="primary"
              v-on="on"
            >
              <v-icon small>
                mdi-check-circle
              </v-icon>
              Für zweiten BA vormerken
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Für zweiten BA vormerken?</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-data-table
                  :headers="reportHeaders"
                  :items="selected"
                  item-key="processInstanceId"
                  class="elevation-1"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :footer-props="{
                    showFirstLastPage: true,
                    showCurrentPage: true,
                    'items-per-page-text': 'Zeilen pro Seite:',
                    'items-per-page-options': [10, 25, 50, 100]
                  }"
                >
                </v-data-table>
                <div class="button-wrapper">
                  <v-btn
                    :disabled="selected.length === 0 || disableButtons"
                    color="green"
                    text
                    @click="
                      execute(selected, 'save-for-second-construction-phase')
                    "
                  >
                    <v-icon small>
                      mdi-check-circle
                    </v-icon>
                    Akzeptieren
                  </v-btn>
                  <v-btn
                    color="red"
                    text
                    @click="closeSaveForSecondConstructionPhaseDialog"
                  >
                    <v-icon dark>
                      mdi-cancel
                    </v-icon>
                    Abbrechen
                  </v-btn>
                  <v-alert
                    v-if="selectedWarning"
                    type="warning"
                    dense
                    text
                  >
                    Achtung! Es wurden {{ selected.length }} Verträge ausgewählt.
                  </v-alert>
                </div>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
      <div class="text">
        <v-dialog v-model="dialogReject" max-width="1000px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              :disabled="selected.length === 0"
              color="primary"
              v-on="on"
            >
              <v-icon small>
                mdi-close-circle
              </v-icon>
              Ablehnen
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span
                class="text-h5"
              >Projektstatus für diese Adressen ablehnen?</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-data-table
                  :headers="reportHeaders"
                  :items="selected"
                  item-key="processInstanceId"
                  class="elevation-1"
                  :footer-props="{
                    showFirstLastPage: true,
                    showCurrentPage: true,
                    'items-per-page-text': 'Zeilen pro Seite:',
                    'items-per-page-options': [10, 25, 50, 100]
                  }"
                >
                </v-data-table>
                <div class="button-wrapper">
                  <v-btn
                    :disabled="selected.length === 0 || disableButtons"
                    color="green"
                    text
                    @click="execute(selected, 'reject')"
                  >
                    <v-icon small>
                      mdi-close-circle
                    </v-icon>
                    Ablehnen
                  </v-btn>
                  <v-btn
                    color="red"
                    text
                    @click="closeRejectDialog"
                  >
                    <v-icon dark>
                      mdi-cancel
                    </v-icon>
                    Abbrechen
                  </v-btn>
                  <v-alert
                    v-if="selectedWarning"
                    type="warning"
                    dense
                    text
                  >
                    Achtung! Es wurden {{ selected.length }} Verträge ausgewählt.
                  </v-alert>
                </div>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import Loader from '@/components/elements/Loader.vue';
import { objectToQueryParamString } from '@/util/ObjectUtil.js';
import { LocalDate } from '@js-joda/core';

export default {
  name: 'HomePassedProcessDashboard',
  components: {
    Loader
  },
  data: () => ({
    options: {},
    selected: [],
    reportHeaders: [
      {
        text: 'Adresse',
        value: 'address'
      },
      {
        text: 'Vertrag',
        align: 'start',
        value: 'contractId',
        filterable: false
      },
      {
        text: 'Projekt',
        align: 'start',
        value: 'project',
        filterable: false
      },
      {
        text: 'Auftragsdatum',
        value: 'contractDate',
        filterable: false
      },
      {
        text: 'Home Passed Grund',
        value: 'homePassedReason',
        filterable: false
      }
    ],
    reportData: [],
    dialogAccept: false,
    dialogReject: false,
    dialogSaveForSecondConstructionPhase: false,
    searchText: '',
    sortBy: '',
    sortDesc: true,
    totalDataTableCount: 0,
    dataLoading: false,
    oldItemsPerPage: undefined,
    disableButtons: false,
    disableButtonsTimeout: null,
  }),
  computed: {
    userGroups() {
      return this.$auth.user().userRoles;
    },
    selectedWarning() {
      return this.selected.length >= 50;
    }
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        this.oldItemsPerPage = oldVal.itemsPerPage;
        this.getData();
      },
      deep: true
    },
    searchText(e) {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);
      this.searchTimeoutId = setTimeout(() => {
        Object.assign(this.options, { page: 1 });
        this.getData(this.startDate);
      }, 1000);
    },
    dialogSaveForSecondConstructionPhase(newVal) {
      this.checkDisableButton(newVal);
    },
    dialogAccept(newVal) {
      this.checkDisableButton(newVal);
    },
    dialogReject(newVal) {
      this.checkDisableButton(newVal);
    },
  },
  created: function () {
    this.getData();
  },
  methods: {
    customFilter(_, search, item) {
      if (search === null || search === '') {
        return true;
      }
      const itemString = [
        item.address != null ? item.address.toLowerCase() : null,
        item.projectName != null ? item.projectName.toLowerCase() : null,
        item.projectDescription != null
          ? item.projectDescription.toLowerCase()
          : null,
        item.contractDate
      ].join('|');
      return itemString.includes(search.toLowerCase());
    },
    rowClicked(item, rowInfo) {
      console.log(item);
      console.log(rowInfo);
      const eventObject = {
        item: item,
        value: !rowInfo.isSelected
      };
      this.itemSelected(eventObject);
    },
    itemSelected(item) {
      if (item.value) {
        // Item was selected
        this.selected.push(
          ...this.reportData.filter(
            (reportItem) => reportItem.address === item.item.address
          )
        );
      } else {
        this.selected = this.selected.filter(
          (selectedItem) => selectedItem.address !== item.item.address
        );
      }
    },
    selectAllTriggered(event) {
      if (event.value) {
        this.selected = this.reportData;
      } else {
        this.selected = [];
      }
    },
    getData() {
      this.dataLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      if (this.searchText === undefined || this.searchText == null) {
        this.searchText = '';
      }
      const searchArray = this.searchText?.trim().split(' ');
      const searchCustomerContractIds = [];
      const searchProjectIds = [];
      searchArray.forEach((element) => {
        if (element !== '' && (element.length >= 6 || !/^\d+$/.test(element))) {
          // Until 6 chars a search String is considered as projectId afterwards as customer or contract ID
          searchCustomerContractIds.push(element);
        } else if (element !== '') {
          searchProjectIds.push(element);
        }
      });
      const queryObject = {
        sortBy: sortBy,
        sortDesc: sortDesc,
        page: page || 1,
        perPage: itemsPerPage || 10,
        search: searchCustomerContractIds.join(' '),
        projectIds: searchProjectIds.join(',')
      };
      const searchQuery = objectToQueryParamString(queryObject);
      HTTP.get(`/orderTask/homePassedProcesses?${searchQuery}`)
        .then((result) => {
          this.reportData = this.buildDashboardData(
            result.data.processInstances
          );
          this.totalDataTableCount = result.data.total;
          if (
            sortBy !== undefined &&
            sortBy != null &&
            sortBy[0] === 'address'
          ) {
            if (sortDesc[0]) {
              this.reportData.sort((a, b) => {
                return a.address < b.address;
              });
            } else {
              this.reportData.sort((a, b) => {
                return a.address > b.address;
              });
            }
          }
          if (
            sortBy !== undefined &&
            sortBy != null &&
            sortBy[0] === 'contractDate'
          ) {
            if (sortDesc[0]) {
              this.reportData.sort((a, b) => {
                const dateSplitA = a.contractDate.split('.');
                const dateA = LocalDate.of(
                  parseInt(dateSplitA[2]),
                  parseInt(dateSplitA[1]),
                  parseInt(dateSplitA[0])
                );
                const dateSplitB = b.contractDate.split('.');
                const dateB = LocalDate.of(
                  parseInt(dateSplitB[2]),
                  parseInt(dateSplitB[1]),
                  parseInt(dateSplitB[0])
                );
                return dateB.isAfter(dateA);
              });
            } else {
              this.reportData.sort((a, b) => {
                const dateSplitA = a.contractDate.split('.');
                console.log(dateSplitA);
                const dateA = LocalDate.of(
                  parseInt(dateSplitA[2]),
                  parseInt(dateSplitA[1]),
                  parseInt(dateSplitA[0])
                );
                const dateSplitB = b.contractDate.split('.');
                const dateB = LocalDate.of(
                  parseInt(dateSplitB[2]),
                  parseInt(dateSplitB[1]),
                  parseInt(dateSplitB[0])
                );
                return dateA.isAfter(dateB);
              });
            }
          }
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Daten konnten nicht geladen werden.'
            )
          )
        )
        .finally(() => {
          this.dataLoading = false;
        });
    },
    buildDashboardData(response) {
      return response.map((dataRow) => {
        const installationAddress = this.getAddress(dataRow.customerDto);
        const date = new Date(Date.parse(dataRow.processInfo.orderDate));
        return {
          address: installationAddress,
          projectName: dataRow.processInfo.projectName,
          projectDescription: dataRow.processInfo.projectDescription,
          contractDate: date.toLocaleDateString('de-DE'),
          processInstanceId: dataRow.processInstanceId,
          project: `${dataRow.processInfo.projectId} - ${dataRow.processInfo.projectName}`,
          projectId: dataRow.processInfo.projectId,
          contractId: dataRow.processInfo.contractId,
          homePassedReason: dataRow.processInfo.homePassedReason
        };
      });
    },
    getAddress(addressDetails) {
      if (addressDetails === null) {
        return '';
      }
      var houseDetails = addressDetails.houseNumber;
      if (addressDetails.houseNumberSupplement) {
        houseDetails += addressDetails.houseNumberSupplement;
      }
      return (
        addressDetails.street +
        ' ' +
        houseDetails +
        ', ' +
        addressDetails.zipCode +
        ' ' +
        addressDetails.city
      );
    },
    execute(selectedItems, action) {
      var actionText;
      if (action === 'accept') {
        actionText = 'akzeptiert';
      } else {
        actionText = 'abgelehnt';
      }
      const contractIds = selectedItems.map((element) => element.contractId);
      const processInstanceIds = selectedItems.map(
        (element) => element.processInstanceId
      );
      HTTP.post('/orderTask/homePassedProcesses', {
        contractIdList: contractIds,
        processInstanceIdList: processInstanceIds,
        action: action
      })
        .then((resp) => {
          this.showMessage(
            'success',
            'Der Prozess wurde erfolgreich ' + actionText
          );
        })
        .catch((err) => {
          console.log('error', err);
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Der Prozess konnte nicht ' + actionText + ' werden.'
            )
          );
        })
        .finally(() => {
          if (action === 'accept') {
            this.closeAcceptDialog();
          } else if (action === 'reject') {
            this.closeRejectDialog();
          } else {
            this.closeSaveForSecondConstructionPhaseDialog();
          }
          this.selected = [];
          this.getData();
        });
    },
    checkDisableButton(dialogValue) {
      if (!this.selectedWarning) {
        this.disableButtons = false;
      } else if (dialogValue === true) {
        this.disableButtons = true;
        clearTimeout(this.disableButtonsTimeout);
        this.disableButtonsTimeout = setTimeout(() => {
          this.disableButtons = false;
        }, 2000);
      }
    },
    showMessage(type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    closeRejectDialog() {
      this.dialogReject = false;
    },
    closeSaveForSecondConstructionPhaseDialog() {
      this.dialogSaveForSecondConstructionPhase = false;
    },
    closeAcceptDialog() {
      this.dialogAccept = false;
    }
  }
};
</script>
<style scoped lang="scss">
div.button-wrapper {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 10px;
  div:first-child .v-btn {
    margin-left: 10px;
  }
  div:not(:first-child):not(:last-child) .v-btn {
    margin-left: 10px;
  }
  div:not(:first-child) .v-btn {
    border-left-style: none;
  }
  .v-btn:hover {
    background-color: var(--goe-fontColor-lighter);
  }
}
</style>
